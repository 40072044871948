import { CustomizationProvider } from "@twilio-paste/core/customization";
import { GenericThemeShape, Theme } from "@twilio-paste/core/theme";
import { PropsWithChildren, ReactNode } from "react";
import tc from "tinycolor2";
import { ScoreString } from "../__generated__/client-types";
import "./base.css";
import col, { scoreColorMap, variants } from "./colours";

// We can scale the theme down to below its original size
// to get a tighter, more data-dense UI.
const themeScaleFactor = 1;

const scaleThemeObj = (obj: { [key: string]: string | number }) =>
    Object.keys(obj).reduce((acc, k) => {
        const initial = obj[k];
        if (initial == 0 || `${initial}`.endsWith("%")) {
            return { ...acc, [k]: obj[k] };
        }
        const val = parseFloat(initial as string);
        const result = val * themeScaleFactor;
        return {
            ...acc,
            [k]: `${parseFloat(result.toFixed(2))}rem`,
        };
    }, {});

const codeFontStack = "'Roboto Mono', monospace;";
const fontStack =
    "'Hanken Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;";

export const natcapTheme = {
    fonts: {
        fontFamilyCode: codeFontStack,
        fontFamilyDisplay: fontStack,
        fontFamilyText: fontStack,
    },
    colors: {
        colorBrand: col.darkGreen,
        colorBrandHighlight: col.midGreen,
        colorTextBrandInverse: col.lightGreen,
    },
    backgroundColors: {
        colorBackground: col.midGrey,
        colorBackgroundBody: col.talcum,
        colorBackgroundBodyInverse: col.charcoal,
        colorBackgroundInverse: col.darkGreen,
        colorBackgroundInverseStrong: tc(col.greenMid).darken(10).toString(),
        colorBackgroundBrand: col.blueDark,
        colorBackgroundUser: col.charcoal20,
        colorBackgroundPrimaryWeakest: col.greenLight,
        colorBackgroundPrimaryWeaker: col.greenMidLighter,
        colorBackgroundPrimaryWeak: col.greenMidLight,
        colorBackgroundPrimary: col.coral,
        colorBackgroundPrimaryStronger: tc(col.coral).darken(5).toString(),
        colorBackgroundPrimaryStrongest: tc(col.coral).darken(10).toString(),
        colorBackgroundRowStriped: col.charcoal05,
        // colorBackgroundPrimaryStrong: col.greenMid,
        colorBackgroundDecorative10Weakest: variants.decorative10Weakest,
        colorBackgroundDecorative10Weaker: variants.decorative10Weaker,
        colorBackgroundDecorative10Weak: variants.decorative10Weak,
        colorBackgroundDecorative10: variants.decorative10,
        colorBackgroundDecorative20Weakest: variants.decorative20Weakest,
        colorBackgroundDecorative20Weaker: variants.decorative20Weaker,
        colorBackgroundDecorative20Weak: variants.decorative20Weak,
        colorBackgroundDecorative20: variants.decorative20,
        colorBackgroundDecorative30Weakest: variants.decorative30Weakest,
        colorBackgroundDecorative30Weaker: variants.decorative30Weaker,
        colorBackgroundDecorative30Weak: variants.decorative30Weak,
        colorBackgroundDecorative30: variants.decorative30,
        colorBackgroundDecorative40Weakest: variants.decorative40Weakest,
        colorBackgroundDecorative40Weaker: variants.decorative40Weaker,
        colorBackgroundDecorative40Weak: variants.decorative40Weak,
        colorBackgroundDecorative40: variants.decorative40,
    },
    borderColors: {
        colorBorderInverseWeaker: tc(col.darkGreen).lighten(10).toString(),
        colorBorderUser: col.charcoal50,
        colorBorderPrimaryWeakest: col.greenLight,
        colorBorderPrimaryWeaker: col.greenMidLighter,
        colorBorderPrimaryWeak: col.greenMidLight,
        colorBorderPrimary: col.greenMid,
        colorBorderDecorative10Weaker: col.midBlue,
        colorBorderNeutral: col.charcoal20,
        colorBorderNeutralWeak: col.charcoal10,
        // colorBorderPrimaryStrong: "hotpink",
        // colorBorderPrimaryStronger: "hotpink",
        // colorBorderPrimaryStrongest: "hotpink"
    },
    textColors: {
        colorText: col.charcoal,
        colorTextWeak: variants.textWeak,
        colorTextWeaker: variants.textWeaker,
        colorTextWeakest: variants.textWeakest,
        colorTextPrimary: col.greenDark,
        colorTextInverse: col.talcum,
        colorTextInverseWeak: tc(col.talcum).darken(10).toString(),
        colorTextBrandHighlight: col.midGreen,
        colorTextBrandInverse: col.talcum,
        colorTextDecorative10: col.midBlue,
        colorTextDecorative20: col.midGreen,
        colorTextDecorative30: col.orange,
        colorTextDecorative40: col.pink,
        colorTextDecorative50: tc(col.midGreen).lighten(20).toString(),
        colorTextLinkWeak: "hotpink",
        colorTextLink: col.coral,
        colorTextLinkHover: tc(col.coral).darken(5).toString(),
        colorTextLinkStrong: "hotpink",
        colorTextLinkStronger: tc(col.coral).darken(5).toString(),
        colorTextIconInverse: tc(col.talcum).darken(10).toString(),
    },
    shadows: {
        shadowBorderPrimary: `${col.charcoal50} 0px 0px 0px 1px`,
        shadowBorderPrimaryStrong: `${col.darkGreen} 0px 0px 0px 1px`,
        shadowFocus: `${tc(col.darkGreen)
            .setAlpha(0.5)
            .toString()} 0px 0px 0px 4px`,
        shadowFocusShadowBorder: `${col.charcoal50} 0px 0px 0px 4px`,
        shadowBorderUser: `0 0 0 1px ${col.charcoal50}`,
        shadowBorderInverseWeaker: `0 0 0 1px ${col.talcum20}`,
        shadowBorderInverseStronger: `0 0 0 1px ${col.talcum50}`,
        shadowBorderInverseStrongest: `0 0 0 1px ${col.talcum}`,
        shadowFocusInverse: `0 0 0 2px ${col.greenMid}`,
        shadowBorderDecorative10Weakest: `${variants.decorative10Weakest} 0 0 0 1px`,
        shadowBorderDecorative10Weaker: `${variants.decorative10Weaker} 0 0 0 1px`,
        shadowBorderDecorative10Weak: `${variants.decorative10Weak} 0 0 0 1px`,
        shadowBorderDecorative10: `${variants.decorative10} 0 0 0 1px`,
        shadowBorderDecorative20Weakest: `${variants.decorative20Weakest} 0 0 0 1px`,
        shadowBorderDecorative20Weaker: `${variants.decorative20Weaker} 0 0 0 1px`,
        shadowBorderDecorative20Weak: `${variants.decorative20Weak} 0 0 0 1px`,
        shadowBorderDecorative20: `${variants.decorative20} 0 0 0 1px`,
        shadowBorderDecorative30Weakest: `${variants.decorative30Weakest} 0 0 0 1px`,
        shadowBorderDecorative30Weaker: `${variants.decorative30Weaker} 0 0 0 1px`,
        shadowBorderDecorative30Weak: `${variants.decorative30Weak} 0 0 0 1px`,
        shadowBorderDecorative30: `${variants.decorative30} 0 0 0 1px`,
        shadowBorderDecorative40Weakest: `${variants.decorative40Weakest} 0 0 0 1px`,
        shadowBorderDecorative40Weaker: `${variants.decorative40Weaker} 0 0 0 1px`,
        shadowBorderDecorative40Weak: `${variants.decorative40Weak} 0 0 0 1px`,
        shadowBorderDecorative40: `${variants.decorative40} 0 0 0 1px`,
    },
    fontWeights: {
        fontWeightLight: 300,
        fontWeightNormal: 400,
        fontWeightBold: 700,
    },
};

const concernScoreSharedStyles = {
    padding: "space30",
    borderRadius: "borderRadius20",
    fontWeight: "fontWeightNormal",
    width: "100%",
};

const dataGridHighlightSharedStyles = {
    borderRightWidth: "borderWidth10",
    borderLeftWidth: "borderWidth10",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    borderRightColor: "colorBorderNeutralWeak",
    borderLeftColor: "colorBorderNeutralWeak",
};

const cardSharedStyles = {
    padding: "space40",
    borderRadius: "borderRadius20",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    borderColor: "colorBorderNeutral",
};

export const ThemeCustomizationProvider = ({
    theme,
    children,
}: {
    theme?: Partial<GenericThemeShape>;
    children: ReactNode;
}) => (
    <CustomizationProvider
        baseTheme="default"
        theme={theme}
        elements={{
            TOP_BAR: {
                // Force the topbar over the Leaflet map
                zIndex: "zIndex90",
                paddingLeft: "space0",
            },
            USER_DIALOG: {
                // Force the user dialog over the Leaflet map
                zIndex: "zIndex90",
            },
            MARKER_ICON: {
                rotate: "180deg",
            },
            LIST_ITEM: {
                margin: "space0",
            },
            //@ts-expect-error (twilio paste type mismatch)
            DATA_GRID_CELL_HIGHLIGHT: {
                ...dataGridHighlightSharedStyles,
                padding: "space50",
            },
            //@ts-expect-error (twilio paste type mismatch)
            DATA_GRID_HEADER_HIGHLIGHT: {
                ...dataGridHighlightSharedStyles,
                verticalAlign: "middle",
            },
            DATA_GRID_HEADER: {
                verticalAlign: "middle",
            },
            DATA_GRID_ROW: {
                verticalAlign: "top",
            },
            DATA_GRID_CELL_BORDERLESS: {
                borderBottomWidth: "borderWidth0",
            },
            METRICS_TABLE_ROW_DISCLOSURE_CONTENT: {
                padding: "space0",
            },
            CONCERN_SCORE_VERY_HIGH: {
                ...concernScoreSharedStyles,
                //@ts-expect-error (using color prop)
                backgroundColor:
                    scoreColorMap[ScoreString.VeryHigh].backgroundColor,
                svg: {
                    color: scoreColorMap[ScoreString.VeryHigh].color,
                },
            },
            CONCERN_SCORE_HIGH: {
                ...concernScoreSharedStyles,
                //@ts-expect-error (using color prop)
                backgroundColor:
                    scoreColorMap[ScoreString.High].backgroundColor,
                svg: {
                    color: scoreColorMap[ScoreString.High].color,
                },
            },
            CONCERN_SCORE_MEDIUM: {
                ...concernScoreSharedStyles,
                //@ts-expect-error (using color prop)
                backgroundColor:
                    scoreColorMap[ScoreString.Medium].backgroundColor,
                svg: {
                    color: scoreColorMap[ScoreString.Medium].color,
                },
            },
            CONCERN_SCORE_LOW: {
                ...concernScoreSharedStyles,
                //@ts-expect-error (using color prop)
                backgroundColor: scoreColorMap[ScoreString.Low].backgroundColor,
                svg: {
                    color: scoreColorMap[ScoreString.Low].color,
                },
            },
            CONCERN_SCORE_VERY_LOW: {
                ...concernScoreSharedStyles,
                //@ts-expect-error (using color prop)
                backgroundColor:
                    scoreColorMap[ScoreString.VeryLow].backgroundColor,
                svg: {
                    color: scoreColorMap[ScoreString.VeryLow].color,
                },
            },
            //@ts-expect-error (twilio paste type mismatch)
            CONCERN_SCORE_NO_SCORE: {
                ...concernScoreSharedStyles,
                backgroundColor: "transparent",
                borderWidth: "borderWidth10",
                borderStyle: "dashed",
                borderColor: "colorBorderWeak",
            },
            CONCERN_SCORE_NO_SCORE_ICON: {
                display: "none",
            },
            SMALL_SELECT: {
                width: "size20",
                boxShadow: "none",
            },
            SMALL_SELECT_ELEMENT: {
                fontSize: "fontSize10",
                padding: "space0",
                margin: "space0",
            },
            INVERSE_TEXT: {
                color: "colorTextBrandInverse",
            },
            INPUT_ELEMENT: {
                color: "colorText",
            },
            PRIMARY_BUTTON: {
                "&:not([disabled]):hover, &:not([disabled]):focus": {
                    backgroundColor: "colorBackgroundPrimaryStrongest",
                    color: "colorTextBrandInverse",
                },
            },
            LINK_BUTTON: {
                fontWeight: "fontWeightNormal",
                color: "colorTextLink",
                textDecoration: "underline",
                "&:hover, &:focus": {
                    color: "colorTextLinkHover",
                    textDecoration: "none",
                },
            },
            MODAL_OVERLAY: {
                backgroundColor: "none",
                //@ts-expect-error (paste doesn't include a value for this)
                zIndex: "1000",
            },
            SIDE_MODAL_CONTAINER: {
                //@ts-expect-error (paste doesn't include a value for this)
                zIndex: "1000",
            },
            SIDE_MODAL: {
                width: "size70",
            },
            MULTISELECT_COMBOBOX: {
                width: "size30",
            },
            COMBOBOX: {
                width: "size30",
            },
            HORIZONTAL_TAB_LIST: {
                '[aria-selected="true"]': {
                    backgroundColor: "colorBackgroundBody",
                },
            },
            HORIZONTAL_TAB_PANEL: {
                padding: "space0",
            },
            HORIZONTAL_TAB_LIST_CHILD: {
                border: "none",
            },
            HORIZONTAL_TAB: {
                borderBottom: "none",
            },
            MULTISELECT_COMBOBOX_PILL: {
                height: "auto",
                borderRadius: "borderRadius30",
                textAlign: "left",
                textTransform: "capitalize",
            },
            MULTISELECT_COMBOBOX_LIST_ITEM_TEXT: {
                textTransform: "capitalize",
            },
            //@ts-expect-error (twilio paste type mismatch)
            CARD: {
                ...cardSharedStyles,
            },
            //@ts-expect-error (twilio paste type mismatch)
            TAB_CARD: {
                ...cardSharedStyles,
                borderTopLeftRadius: "borderRadius0",
            },
            MAP_CONTAINER: {
                zIndex: "zIndex0",
                ".leaflet-container": {
                    borderRadius: "borderRadius20",
                },
            },
            MAIN: {
                backgroundColor: "colorBackground",
            },
            VERTICAL_TAB_PANEL: {
                paddingTop: "space0",
            },
            TNFD_CARD: {
                maxWidth: "80em",
            },
            DISCLOSURE_HEADING: {
                //@ts-expect-error (paste doesn't include a value for this)
                marginTop: "-1px",
                borderRadius: "borderRadius0",
            },
        }}
    >
        {children}
    </CustomizationProvider>
);

const Base = ({ children }: PropsWithChildren) => (
    <Theme.Provider theme="default">
        <Theme.Consumer>
            {({ theme: defaultTheme }) => {
                const sizeKeys: Array<keyof GenericThemeShape> = [
                    "fontSizes",
                    "heights",
                    "widths",
                    "maxHeights",
                    "maxWidths",
                    "minHeights",
                    "minWidths",
                    "iconSizes",
                    "lineHeights",
                    "space",
                    "sizes",
                ];
                const scaledTheme = {
                    ...defaultTheme,
                    ...natcapTheme,
                    ...sizeKeys.reduce(
                        (acc, k) => ({
                            ...acc,
                            [k]: scaleThemeObj(defaultTheme[k]),
                        }),
                        {},
                    ),
                };
                return (
                    <ThemeCustomizationProvider theme={scaledTheme}>
                        {children}
                    </ThemeCustomizationProvider>
                );
            }}
        </Theme.Consumer>
    </Theme.Provider>
);

export default Base;
