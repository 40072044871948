import {
    Anchor,
    Box,
    Button,
    Heading,
    HelpText,
    Input,
    Label,
    Text,
} from "@twilio-paste/core";
import axios from "axios";
import { useState } from "react";
import { getAuthApiUrl } from "../../common/helpers/environmentHelpers";
import imageLeft from "../../common/theme/assets/login-image-left.png";
import imageRight from "../../common/theme/assets/login-image-right.png";
import logo from "../../common/theme/assets/logo/logotext/logotext-light-green.png";
import { isEmailValid } from "../../dashboard/src/helpers/stringHelpers";

enum AuthReasonEnum {
    linkExpired = "magic_link_expired",
    sessionExpired = "session_expired",
    invalidState = "invalid_state",
    missingToken = "missing_token",
    unknown = "unknown",
}

interface LoginParams {
    client_id: string;
    reason: AuthReasonEnum;
    redirect_uri: string;
    response_type: string;
    scope: string;
    state: string;
}

export function App() {
    const [email, setEmail] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasInvalidEmail, setHasInvalidEmail] = useState(false);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const entries = Object.fromEntries(urlSearchParams.entries());
    const authParams: LoginParams = {
        client_id: entries.client_id,
        reason: entries.reason as AuthReasonEnum,
        redirect_uri: entries.redirect_uri,
        response_type: entries.response_type,
        scope: entries.scope,
        state: entries.state,
    };

    const [authReason, setAuthReason] = useState<AuthReasonEnum | null>(
        authParams.reason,
    );

    const onSubmit = async () => {
        const apiUrl = getAuthApiUrl();

        try {
            const response = await axios.post(apiUrl, {
                email_address: email,
                ...authParams,
            });

            setIsAuthenticated(response.data === "Email sent");
            setHasInvalidEmail(false);
            setAuthReason(null);
        } catch (error) {
            setHasInvalidEmail(true);
            setAuthReason(null);
            setIsAuthenticated(false);
        }
    };

    const getTextContent = () => {
        switch (true) {
            case isAuthenticated:
                return {
                    heading: "We've sent your link",
                    explanation: null,
                };
            case hasInvalidEmail:
                return {
                    heading: "We couldn't authenticate your email address",
                    explanation: (
                        <>
                            Please try again or contact{" "}
                            <Anchor href="mailto:support@natcapresearch.com">
                                support@natcapresearch.com
                            </Anchor>
                        </>
                    ),
                };
            case authReason === AuthReasonEnum.missingToken:
                return {
                    heading: "Sign-in with your email address",
                    explanation: null,
                };
            case authReason === AuthReasonEnum.linkExpired:
                return {
                    heading: "Your magic link has expired",
                    explanation: (
                        <>
                            For security, the link is valid for three hours
                            only. You can request a new link here.
                        </>
                    ),
                };
            case authReason === AuthReasonEnum.sessionExpired:
                return {
                    heading: "Your access has expired",
                    explanation: (
                        <>
                            For security, you have been logged out of the natcap
                            Dashboard. You can request a new link here.
                        </>
                    ),
                };
            case authReason === AuthReasonEnum.unknown ||
                authReason === AuthReasonEnum.invalidState:
                return {
                    heading: "There was a problem opening the natcap Dashboard",
                    explanation: (
                        <>
                            Try again with a fresh magic link, and if the
                            problem persists please contact support.
                        </>
                    ),
                };
            default:
                return {
                    heading: "Sign-in with your email address",
                    explanation: null,
                };
        }
    };

    const requestNewLink = () => {
        setIsAuthenticated(false);
        setEmail("");
        setAuthReason(null);
    };

    return (
        <Box
            element="INVERSE_TEXT"
            backgroundColor="colorBackgroundInverse"
            height="100vh"
            display="flex"
            flexDirection="column"
            fontSize="fontSize30"
        >
            <Box
                height="100px"
                alignItems="center"
                display="flex"
                padding="space90"
                borderBottomWidth="borderWidth10"
                borderBottomColor="colorBorderPrimaryWeakest"
                borderBottomStyle="solid"
            >
                <img src={logo} alt="natcap" height="35px" />
            </Box>
            <Box
                display="flex"
                flex="1"
                backgroundImage={`url(${imageLeft})`}
                backgroundPosition="100% 100%"
                backgroundRepeat="no-repeat"
                backgroundSize="auto 100%"
            >
                <Box flex="1">
                    <Box padding="space80" width="60%">
                        <Heading as="h1" variant="heading20">
                            <Text
                                as="span"
                                element="INVERSE_TEXT"
                                fontWeight="fontWeightLight"
                                fontSize="fontSize70"
                            >
                                {getTextContent().heading}
                            </Text>
                        </Heading>

                        {isAuthenticated ? (
                            <>
                                <Text color="colorTextBrandInverse" as="div">
                                    <p>
                                        We&apos;ve sent an email to {email}{" "}
                                        containing a link to the natcap
                                        Dashboard.
                                    </p>
                                    <p>
                                        The link is valid for three hours: if it
                                        expires come back to this page and
                                        request a new one.
                                    </p>
                                </Text>
                                <Text
                                    as="a"
                                    onClick={requestNewLink}
                                    element="ANCHOR"
                                >
                                    Request a new link
                                </Text>
                                <Text as="div" color="colorTextBrandInverse">
                                    <p>
                                        Something not working? Contact{" "}
                                        <Anchor href="mailto:support@natcapresearch.com">
                                            support@natcapresearch.com
                                        </Anchor>
                                    </p>
                                </Text>
                            </>
                        ) : (
                            <>
                                {getTextContent().explanation && (
                                    <Text
                                        as="div"
                                        paddingBottom="space50"
                                        element="INVERSE_TEXT"
                                    >
                                        {getTextContent().explanation}
                                    </Text>
                                )}

                                <Box marginBottom="space100">
                                    <Label required element="INVERSE_TEXT">
                                        Email address
                                    </Label>
                                    <Input
                                        required
                                        type="email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        onKeyDown={(e) =>
                                            e.key === "Enter" && onSubmit()
                                        }
                                    />
                                    <HelpText
                                        id="email_help_text"
                                        element="INVERSE_TEXT"
                                    >
                                        We&apos;ll send you an email that will
                                        open your{" "}
                                        <strong>natcap Dashboard</strong>.
                                    </HelpText>
                                    <Box
                                        display="flex"
                                        marginTop="space50"
                                        alignItems="center"
                                    >
                                        <Box marginRight="space50">
                                            <Button
                                                element="PRIMARY_BUTTON"
                                                variant="primary"
                                                onClick={onSubmit}
                                                disabled={!isEmailValid(email)}
                                            >
                                                Send me a link
                                            </Button>
                                        </Box>

                                        <Anchor href="mailto:support@natcapresearch.com">
                                            Didn&apos;t get our link?
                                        </Anchor>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
                <Box
                    flex="1"
                    backgroundImage={`url(${imageRight})`}
                    backgroundPosition="100% 100%"
                    backgroundRepeat="no-repeat"
                    backgroundSize="auto 100%"
                />
            </Box>
        </Box>
    );
}
