import { ScoreString } from "../../../common/__generated__/client-types";

export const toTitleCase = (str: string): string => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const isEmailValid = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const displayScores: { [key in ScoreString]: string } = {
    [ScoreString.High]: "High",
    [ScoreString.Low]: "Low",
    [ScoreString.Medium]: "Medium",
    [ScoreString.VeryHigh]: "Very High",
    [ScoreString.VeryLow]: "Very Low",
};
