import tc from "tinycolor2";
import { ScoreString } from "../__generated__/client-types";

/* Color styles */

const colours = {
    lightBlue: "rgb(204, 226, 235)",
    midBlue: "rgb(80, 146, 170)",
    darkBlue: "rgb(9, 41, 54)",
    lightGreen: "rgb(226, 235, 204)",
    midGreen: "rgb(92, 148, 87)",
    darkGreen: "rgb(26, 52, 23)",
    talcum: "rgb(251, 250, 248)",
    charcoal: "rgb(43, 43, 43)",
    white: "rgb(255, 255, 255)",
    orange: "rgb(242, 170, 68)",
    lightOrange: "rgb(248, 212, 160)",
    pink: "rgb(252, 153, 173)",
    lightPink: "rgb(253, 181, 195)",
    coral: "rgb(250, 109, 90)",
    lightCoral: "rgb(253, 189, 181)",
    purple: "rgb(99, 57, 89)",
    lightPurple: "rgb(208, 174, 200)",
    lightGrey: "rgb(249, 249, 250)",
    midGrey: "rgb(241, 241, 243)",

    // Exported from Figma

    // talcum: "rgba(251, 250, 248, 1)",
    // charcoal: "rgba(43, 43, 43, 1)",
    greenMid: "rgba(92, 148, 87, 1)",
    greenDark: "rgba(26, 52, 23, 1)",
    greenLight: "rgba(226, 235, 204, 1)",
    greenMidLight: "rgba(141, 185, 136, 1)",
    greenMidLighter: "rgba(171, 204, 168, 1)",
    blueDark: "rgba(9, 41, 54, 1)",
    blueLight: "rgba(204, 226, 235, 1)",
    blueMid: "rgba(80, 146, 170, 1)",
    purpleMid: "rgba(99, 57, 89, 1)",
    purpleLight: "rgba(208, 174, 200, 1)",
    pinkMid: "rgba(252, 153, 173, 1)",
    pinkLight: "rgba(253, 181, 195, 1)",
    orangeMid: "rgba(242, 170, 68, 1)",
    orangeLight: "rgba(248, 212, 160, 1)",
    orangeLightLighter: "rgba(251, 225, 188, 1)",
    charcoal05: "rgba(43, 43, 43, 0.05)",
    charcoal10: "rgba(43, 43, 43, 0.1)",
    charcoal20: "rgba(43, 43, 43, 0.2)",
    charcoal50: "rgba(43, 43, 43, 0.5)",
    talcum50: "rgba(251, 250, 248, 0.5)",
    talcum20: "rgba(251, 250, 248, 0.2)",
    utilityTransparent: "rgba(255, 255, 255, 0)",
    coralMid: "rgba(250, 109, 90, 1)",
    coralLight: "rgba(253, 189, 181, 1)",
    coralMidLight: "rgba(251, 141, 126, 1)",
    coralMidLighter: "rgba(253, 198, 190, 1)",
    coralMidDark: "rgba(244, 97, 78, 1)",
    coralLightLighter: "rgba(254, 220, 215, 1)",
};

const col = colours;

export default colours;

export const variants = {
    textWeakest: tc(col.charcoal).lighten(50).toString(),
    textWeaker: tc(col.charcoal).lighten(40).toString(),
    textWeak: tc(col.charcoal).lighten(30).toString(),
    text: col.charcoal,
    decorative10Weakest: tc(col.coral).lighten(30).toString(),
    decorative10Weaker: tc(col.coral).lighten(20).toString(),
    decorative10Weak: tc(col.coral).lighten(10).toString(),
    decorative10: tc(col.coral).lighten(5).toString(),
    decorative20Weakest: tc(col.pink).lighten(19).toString(),
    decorative20Weaker: tc(col.pink).lighten(13).toString(),
    decorative20Weak: tc(col.pink).lighten(10).toString(),
    decorative20: tc(col.pink).lighten(5).toString(),
    decorative30Weakest: tc(col.orange).lighten(35).toString(),
    decorative30Weaker: tc(col.orange).lighten(25).toString(),
    decorative30Weak: tc(col.orange).lighten(15).toString(),
    decorative30: tc(col.orange).lighten(10).toString(),
    decorative40Weakest: tc(col.midBlue).lighten(45).toString(),
    decorative40Weaker: tc(col.midBlue).lighten(35).toString(),
    decorative40Weak: tc(col.midBlue).lighten(30).toString(),
    decorative40: tc(col.midBlue).lighten(25).toString(),
};

type ScoreColorMap = {
    [key in ScoreString]: { color: string; backgroundColor: string };
};

export const scoreColorMap: ScoreColorMap = {
    [ScoreString.VeryHigh]: {
        color: colours.coralMidDark,
        backgroundColor: colours.coralLight,
    },
    [ScoreString.High]: {
        color: colours.coralMidLight,
        backgroundColor: colours.coralLightLighter,
    },
    [ScoreString.Medium]: {
        color: colours.orange,
        backgroundColor: colours.orangeLightLighter,
    },
    [ScoreString.Low]: {
        color: colours.greenMidLight,
        backgroundColor: colours.greenLight,
    },
    [ScoreString.VeryLow]: {
        color: colours.greenMid,
        backgroundColor: colours.greenMidLighter,
    },
};
