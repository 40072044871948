import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Base from "../../common/theme/base";
import { App } from "./App";

const container = document.getElementById("app");
const root = createRoot(container as HTMLElement);
root.render(
    <StrictMode>
        <Base>
            <App />
        </Base>
    </StrictMode>,
);
